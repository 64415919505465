import { SystemStyleObject } from '@chakra-ui/react';

import { ButtonVariants } from '@/components/atoms';

const baseStyle: SystemStyleObject = {
  borderRadius: 7,
  fontWeight: `bold`,
  border: `2px solid`,
};

const variants: Record<ButtonVariants, SystemStyleObject> = {
  green: {
    color: `hsl(111, 12%, 56%)`,
    background: `transparent`,
    borderColor: `hsl(111, 12%, 56%)`,
  },
  pink: {
    color: `hsl(5, 29%, 74%)`,
    background: `transparent`,
    borderColor: `hsl(5, 29%, 74%)`,
  },
  black: {
    color: `black`,
    backgroundColor: `transparent`,
    borderColor: `black`,
  },
  white: {
    color: `white.main`,
    backgroundColor: `transparent`,
    borderColor: `white.main`,
  },
};

const defaultProps: SystemStyleObject = {};

export const Button = {
  baseStyle,
  variants,
  defaultProps,
};
