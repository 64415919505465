import React from 'react';

export const Root = (element: HTMLElement): JSX.Element => {
  return (
    <>
      {/* <Fonts /> */}
      <>{element.children}</>
    </>
  );
};
