import {
  Button,
  Heading,
  Input,
  Link,
  Text,
  Textarea,
} from '@/theme/components';
import { colors, typography } from '@/theme/foundations';

export const theme = {
  styles: {
    global: {
      body: {
        fontFamilly: `Quicksand, sans-serif`,
      },
    },
  },
  colors,
  ...typography,
  components: {
    Button,
    Heading,
    Input,
    Link,
    Text,
    Textarea,
  },
};
