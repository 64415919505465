export const colors = {
  colors: {
    black: `hsl(0, 0%, 0%)`,
    white: `hsl(0, 0%, 100%)`,
    primary: {
      red: `hsl(0, 45%, 50%)`,
      blue: `hsl(225, 56%, 33%)`,
      dark: `hsl(243, 47%, 36%)`,
      green: `hsl(111, 12%, 56%)`,
      pink: `hsl(5, 29%, 74%)`,
    },
    blue: {
      75: `hsl(200, 100%, 92%)`,
      100: `hsl(202, 81%, 86%)`,
      200: `hsl(203, 82%, 76%)`,
      600: `hsl(211, 61%, 43%)`,
      700: `hsl(213, 49%, 34%)`,
      800: `hsl(215, 41%, 28%)`,
      900: `hsl(225, 56%, 33%)`,
    },
    red: {
      75: `hsl(358, 100%, 95%)`,
      100: `hsl(0, 95%, 92%)`,
      400: `hsl(0, 88%, 68%)`,
      500: `hsl(0, 76%, 57%)`,
      600: `hsl(0, 45%, 50%)`,
    },
    gray: {
      50: `hsl(204, 45%, 98%)`,
      75: `hsl(216, 63%, 97%)`,
      100: `hsl(210, 38%, 95%)`,
      200: `hsl(214, 32%, 91%)`,
      300: `hsl(211, 25%, 84%)`,
      400: `hsl(214, 20%, 69%)`,
      500: `hsl(216, 15%, 52%)`,
      600: `hsl(218, 17%, 35%)`,
      700: `hsl(218, 23%, 23%)`,
      800: `hsl(220, 26%, 14%)`,
      900: `hsl(230, 21%, 11%)`,
    },
    yellow: {
      100: `hsl(58, 97%, 87%)`,
      200: `hsl(55, 92%, 76%)`,
      300: `hsl(51, 89%, 67%)`,
      400: `hsl(47, 81%, 61%)`,
      500: `hsl(40, 67%, 51%)`,
      800: `hsl(30, 76%, 26%)`,
      900: `hsl(30, 74%, 21%)`,
    },
    orange: {
      100: `hsl(39, 96%, 89%)`,
      400: `hsl(27, 84%, 57%)`,
      500: `hsl(24, 75%, 50%)`,
      900: `hsl(14, 60%, 25%)`,
    },
    purple: {
      50: `hsl(270, 100%, 98%)`,
      60: `hsl(269, 100%, 97%)`,
      75: `hsl(269, 100%, 95%)`,
      100: `hsl(268, 90%, 92%)`,
      200: `hsl(265, 86%, 86%)`,
      500: `hsl(259, 59%, 59%)`,
      600: `hsl(258, 50%, 52%)`,
      700: `hsl(256, 44%, 42%)`,
      800: `hsl(254, 41%, 34%)`,
      900: `hsl(254, 40%, 25%)`,
    },
    green: {
      100: `hsl(139, 73%, 87%)`,
      200: `hsl(141, 60%, 75%)`,
      300: `#68D391`,
      400: `#48BB78`,
      500: `#38A169`,
      600: `hsl(150, 48%, 35%)`,
      800: `hsl(152, 42%, 23%)`,
      900: `hsl(152, 42%, 19%)`,
    },
    teal: {
      50: `#E6FFFA`,
      75: `hsl(170, 77%, 90%)`,
      100: `hsl(170, 77%, 83%)`,
      200: `hsl(172, 67%, 70%)`,
      300: `#38B2AC`,
      400: `#38B2AC`,
      500: `#319795`,
      600: `hsl(181, 47%, 33%)`,
      700: `hsl(183, 42%, 27%)`,
      800: `hsl(185, 40%, 23%)`,
      900: `hsl(186, 40%, 19%)`,
    },
    pink: {
      75: `hsl(348, 100%, 96%)`,
      100: `hsl(343, 95%, 92%)`,
      200: `hsl(339, 90%, 85%)`,
      300: `hsl(336, 86%, 75%)`,
      400: `hsl(331, 79%, 66%)`,
      500: `hsl(329, 64%, 54%)`,
      800: `hsl(318, 51%, 29%)`,
      900: `hsl(319, 50%, 21%)`,
    },
  },
};

export type Colors = typeof colors;
